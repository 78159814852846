import React, { useState } from "react";
import "./NewUpdate.css";
import Modal from "../Modal/Modal";
import { submitMessage, finalMessage } from "../../services/updatesService"; // Adjust path as needed
import ReactMarkdown from "react-markdown";

const NewUpdate = ({ goals = [], onSubmit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [text, setText] = useState("");
  const [updateType, setUpdateType] = useState("General"); // Default to "General"
  const [selectedGoal, setSelectedGoal] = useState(""); // Default to no selection
  const [assistantMessage, setAssistantMessage] = useState(""); // Store assistant message from API
  const [isLoading, setIsLoading] = useState(false); // Indicate submission is in progress
  const [isEditing, setIsEditing] = useState(false); // Control edit mode for assistant message
  const [updateId, setUpdateId] = useState("");
  const [newContext, setNewContext] = useState(""); // For adding new context
  const [activeAction, setActiveAction] = useState(""); // Tracks the current action
  const userId = localStorage.getItem("userId");

  const handleSubmit = async () => {
    try {
      setIsLoading(true); // Start loading
      const response = await submitMessage(
        text,
        selectedGoal,
        userId,
        updateType.toLowerCase() // Ensure type is lowercase
      );
      console.log("Response from API:", response);
      setAssistantMessage(response.assistant_message); // Set the assistant message from the API
      setUpdateId(response.update_id); // Set the update ID from the API response
    } catch (error) {
      console.error("Error submitting update:", error.message);
      alert("An error occurred while submitting the update. Please try again.");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleFinalMessage = async (action, message) => {
    try {
      setIsLoading(true); // Start loading
      console.log(`Sending final message with action: ${action}`);
      
      const response = await finalMessage(action, message, updateId);
      console.log("Final message response:", response);
      
      setAssistantMessage(response.assistant_message);
      
      if (!["shorter", "context"].includes(action)) {
        // Call the onSubmit callback and close the modal
        if (onSubmit) {
          onSubmit();
        }
        setIsModalOpen(false); // Close the modal
      }
    } catch (error) {
      console.error(`Error sending final message:`, error.message);
      alert(`Failed to process action '${action}'. Please try again.`);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const updateTypes = ["General", "Notes", "Transcript"];

  return (
    <>
      {/* Button to Open Modal */}
      <button
                className="new-summary-button"
                onClick={() => setIsModalOpen(true)}
            >
                + New Update
      </button>
  
      {/* Modal */}
      {isModalOpen && (
        <Modal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="new-update-container">
            <h2 className="new-update-header">New Update</h2>
  
            {/* Hide this section when assistantMessage exists */}
            {!assistantMessage && (
              <>
                <div className="pills-container">
                  {updateTypes.map((type) => (
                    <button
                      key={type}
                      onClick={() => setUpdateType(type)}
                      className={`pill ${updateType === type ? "selected" : ""}`}
                    >
                      {type}
                    </button>
                  ))}
                </div>
  
                {/* Text area for update content */}
                <label htmlFor="Textarea" className="goal-dropdown-label">
                  UPDATE:
                </label>
                <textarea
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  className="new-update-textarea"
                  placeholder="Type or paste your text here..."
                />
  
                {/* Dropdown for selecting a related goal */}
                {goals.length > 0 && (
                  <div className="goal-dropdown-container">
                    <label htmlFor="goal-select" className="goal-dropdown-label">
                      RELATED PROJECT:
                    </label>
                    <select
                      id="goal-select"
                      value={selectedGoal}
                      onChange={(e) => setSelectedGoal(e.target.value)}
                      className="goal-dropdown"
                    >
                      {/* Automatically select the only available goal if there's exactly one */}
                      {goals.length === 1 ? (
                        goals.map((goal) => (
                          <option key={goal.goal_id} value={goal.goal_id}>
                            {goal.title}
                          </option>
                        ))
                      ) : (
                        <>
                          <option value="">-- Select a project --</option>
                          {goals.map((goal) => (
                            <option key={goal.goal_id} value={goal.goal_id}>
                              {goal.title}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                )}
              </>
            )}
  
            {/* Submit button */}
            {!assistantMessage && (
              <button
                className="new-update-submit-button"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            )}
  
            {/* Display assistant message and actions after receiving response */}
            {assistantMessage && (
              <div className="assistant-message-container">
                <h3 className="additional-actions-heading">CLEANED UPDATE:</h3>
  
                {/* Always Display Assistant Message */}
                {activeAction !== "edited" && (
                  <div className="markdown-preview">
                    <ReactMarkdown>{assistantMessage}</ReactMarkdown>
                  </div>
                )}
  
                {isEditing ? (
                  <>
                    {/* Edit Mode */}
                    <textarea
                      value={assistantMessage}
                      onChange={(e) => setAssistantMessage(e.target.value)}
                      className="new-update-textarea"
                      placeholder="Edit the AI-generated message..."
                    />
                    <button
                      className="new-update-submit-button"
                      onClick={() => {
                        handleFinalMessage("edited", assistantMessage);
                        setIsEditing(false); // Exit edit mode
                        setActiveAction(""); // Reset action
                      }}
                      disabled={isLoading}
                    >
                      Submit Edited Message
                    </button>
                  </>
                ) : activeAction === "context" ? (
                  <>
                    {/* Context Mode */}
                    <textarea
                      value={newContext}
                      onChange={(e) => setNewContext(e.target.value)}
                      className="new-update-textarea context-textarea" // Smaller textarea for additional context
                      placeholder="Add additional context here..."
                    />
                    <button
                      className="new-update-submit-button"
                      onClick={() => {
                        handleFinalMessage("context", newContext);
                        setActiveAction(""); // Reset action
                      }}
                      disabled={isLoading}
                    >
                      Submit Additional Context
                    </button>
                  </>
                ) : null}
  
                {/* Conditionally Render "Looks Good ->" Button */}
                {activeAction !== "edited" && activeAction !== "context" && (
                  <button
                    className="primary-action-button"
                    onClick={async () => {
                      try {
                        setIsLoading(true); // Indicate loading
                        await handleFinalMessage("", assistantMessage); // Call finalMessage with null action
                        setIsLoading(false);
                        setActiveAction(""); // Reset action
                        window.location.reload(); // Reload the page
                      } catch (error) {
                        console.error(
                          "Error submitting final message:",
                          error.message
                        );
                      }
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing..." : "Looks Good ->"}
                  </button>
                )}
  
                {/* Additional Actions Section */}
                <div className="additional-actions-container">
                  <h3 className="additional-actions-heading">
                    Additional Actions:
                  </h3>
                  <div className="action-buttons-container">
                    <button
                      className="secondary-action-button"
                      onClick={() => {
                        handleFinalMessage("shorter", assistantMessage);
                        setActiveAction(""); // Reset action
                        setIsEditing(false); // Ensure editing is disabled
                      }}
                    >
                      Make it shorter
                    </button>
                    <button
                      className="secondary-action-button"
                      onClick={() => {
                        setIsEditing(true); // Enable edit mode
                        setActiveAction("edited");
                      }}
                    >
                      Edit it
                    </button>
                    <button
                      className="secondary-action-button"
                      onClick={() => {
                        setNewContext(""); // Reset context input
                        setActiveAction("context");
                        setIsEditing(false); // Ensure editing is disabled
                      }}
                    >
                      Add more context
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
);
}

export default NewUpdate;

import React, { useState } from "react";
import "./NewSummary.css";
import Modal from "../Modal/Modal";
import { newSummary } from "../../services/summarizeService";

const NewSummary = ({ onSubmit, goal_id }) => {
    console.log(goal_id)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scope, setScope] = useState("allTime");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedSummaryType, setSelectedSummaryType] = useState("");

    const summaryTypes = [
        "Project Charter",
        "Weekly Summary",
        "Project Delivery",
        "Project Scoping",
        "Goal Progress Report",
    ];

    const handleSubmit = async () => {
        const userId = localStorage.getItem("userId"); // Retrieve user ID from localStorage
        try {
            const response = await newSummary(
                userId, // Pass the user ID
                goal_id, // Pass the goal ID
                "true", // force_update is always "true"
                selectedSummaryType, // reportType
                scope === "custom" ? startDate : null, // Pass startDate if "custom" is selected
                scope === "custom" ? endDate : null    // Pass endDate if "custom" is selected
            );
            console.log("New Summary Response:", response);
            if (onSubmit) onSubmit(response); // Pass response back to parent
            setIsModalOpen(false);
        } catch (error) {
            console.error("Error generating summary:", error);
            alert("An error occurred. Please try again.");
        } finally {
        }
    };

    return (
        <>
            <button
                className="new-summary-button"
                onClick={() => setIsModalOpen(true)}
            >
                + New Summary
            </button>

            {isModalOpen && (
                <Modal isVisible onClose={() => setIsModalOpen(false)}>
                    <div className="new-summary-container">
                        <h2 className="new-summary-header">Generate New Summary</h2>

                        {/* Summary Scope */}
                        <div className="section">
                            <h3 className="section-header">Summary Scope</h3>
                            <div className="pills-container">
                                <button
                                    className={`pill ${
                                        scope === "allTime" ? "selected" : ""
                                    }`}
                                    onClick={() => setScope("allTime")}
                                >
                                    All Time
                                </button>
                                <button
                                    className={`pill ${
                                        scope === "custom" ? "selected" : ""
                                    }`}
                                    onClick={() => setScope("custom")}
                                >
                                    Custom
                                </button>
                            </div>
                            {scope === "custom" && (
                                <div className="date-inputs">
                                    <div className="date-input">
                                        <label>Start Date</label>
                                        <input
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="date-input">
                                        <label>End Date</label>
                                        <input
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Summary Type */}
                        <div className="section">
                            <h3 className="section-header">Summary Type</h3>
                            <div className="pills-container">
                                {summaryTypes.map((type) => (
                                    <button
                                        key={type}
                                        className={`pill ${
                                            selectedSummaryType === type ? "selected" : ""
                                        }`}
                                        onClick={() => setSelectedSummaryType(type)}
                                    >
                                        {type}
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Submit Button */}
                        <button
                            className="new-summary-submit-button"
                            onClick={handleSubmit}
                            disabled={!selectedSummaryType}
                        >
                            Generate using AI
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default NewSummary;

import React, { useState } from "react";
import "./NewProject.css";
import Modal from "../Modal/Modal";
import { createNewGoal } from "../../services/goalService";

const NewProject = ({ onSubmit }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const userId = localStorage.getItem("userId");

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            await createNewGoal(userId, title, description, dueDate);
            setIsModalOpen(false);
            if (onSubmit) onSubmit(); // Trigger parent refresh
        } catch (error) {
            console.error("Error creating project:", error.message);
            alert("An error occurred. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <button
                className="new-project-button"
                onClick={() => setIsModalOpen(true)}
            >
                + New Project
            </button>

            {isModalOpen && (
                <Modal isVisible onClose={() => setIsModalOpen(false)}>
                    <div className="new-project-container">
                        <h2 className="new-project-header">New Project</h2>
                        <label className="project-input-label">Title:</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="project-input"
                            placeholder="Enter project title"
                        />
                        <label className="project-input-label">Description:</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="project-textarea"
                            placeholder="Enter project description"
                        />
                        <label className="project-input-label">Due Date:</label>
                        <input
                            type="date"
                            value={dueDate}
                            onChange={(e) => setDueDate(e.target.value)}
                            className="project-input"
                        />
                        <button
                            onClick={handleSubmit}
                            className="new-project-submit-button"
                            disabled={isLoading || !title.trim()}
                        >
                            {isLoading ? "Creating..." : "Submit"}
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default NewProject;

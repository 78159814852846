import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PREFIX;
const AUTH_TOKEN = process.env.REACT_APP_ENTROV_TOKEN;
const GMAIL_API_URL = 'https://www.googleapis.com/gmail/v1/users/me/messages/send';


// Function to fetch Google user profile
export const fetchGoogleUserProfile = async (accessToken) => {
  try {
    const response = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Google user profile:', error);
    throw error;
  }
};

// Function to log in or create a user
export const loginUser = async (userProfile) => {
    // Get the user's current time zone
  const getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  // Example usage
  const timeZone = getUserTimeZone();
  console.log("User's current time zone:", timeZone);
  try {
    const response = await axios.post(`${API_URL}/create-user/`, {
      first_name: userProfile.given_name,  // First name from Google response
      last_name: userProfile.family_name,  // Last name from Google response
      email: userProfile.email,            // Email from Google response
      google_id: userProfile.id            // Google ID from Google response
    },
    {
      headers: {
        'Authorization': `${AUTH_TOKEN}`,  // Example of adding an Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

// Function to send an email using Gmail API
export const sendEmail = async (accessToken, to, subject, message) => {
  try {
    // Compose the email
    const email = `
      From: me
      To: ${to}
      Subject: ${subject}

      ${message}
    `;

    // Encode the email to base64url
    const base64EncodedEmail = btoa(unescape(encodeURIComponent(email))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    // Make the API request to send the email
    const response = await axios.post(
      GMAIL_API_URL,
      { raw: base64EncodedEmail },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,  // Use the access token provided by Google
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PREFIX;
const AUTH_TOKEN = process.env.REACT_APP_ENTROV_TOKEN;


export const linkGoals = async (updateId, goalIds) => {
  try {
    const response = await axios.post(`${API_URL}/linkGoals/`, {
      update_id:updateId,
      goal_ids: goalIds,
    },
    {
      headers: {
          'Authorization': `${AUTH_TOKEN}`
      }
  });
    return response.data;
  } catch (error) {
    console.error('Error submitting message:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};
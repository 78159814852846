import axios from 'axios';
import dayjs from 'dayjs';

const API_URL = process.env.REACT_APP_API_PREFIX;
const AUTH_TOKEN = process.env.REACT_APP_ENTROV_TOKEN;


export const fetchUserGoals = async (userId, archived = "False") => {
  
    try {
      const response = await fetch(`${API_URL}/goals/fetchAll/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${AUTH_TOKEN}`
          },
          body: JSON.stringify({
              user_id: userId,
              is_archived: archived,
          }),
      });

      if (!response.ok) {
          throw new Error('Failed to fetch user goals');
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error fetching user goals:', error);
      throw error;
  }
};



export const fetchSingleGoal = async (goalId) => {
  try {
      const response = await fetch(`${API_URL}/goals/fetchSingle/`, {
          method: 'POST',  // Ensure POST method is used
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${AUTH_TOKEN}`
          },
          body: JSON.stringify({
              goal_id: goalId,
          }),
      });

      if (!response.ok) {
          throw new Error('Failed to fetch the single goal');
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error fetching single goal:', error);
      throw error;
  }
};

// Function to create new user goal
export const createNewGoal = async (userid,title,description = null,due_date = null) => {
    // Check if due_date is null, and set the default date as today + 1 month
    if (!due_date) {
        due_date = dayjs().add(1, 'month').format('YYYY-MM-DD');
      }
    try{
    const response = await axios.post(`${API_URL}/create-goal/` , {
      user_id: userid,
      title: title,
      description: description,
      due_date: due_date
    },
    {
        headers: {
            'Authorization': `${AUTH_TOKEN}`
        }
    }
);
    return response.data;
  } catch (error) {
    console.error('Error creating goal:',error);
    throw error;
  }
  }


// Function to create new user goal
export const updateGoal = async (userid,goalid,title=null,description = null,status=null,isarchived=null,due_date = null,progress=null) => {
    if (due_date) {
      due_date = dayjs(due_date).format('YYYY-MM-DD');
      }
    try{
      const response = await axios.post(`${API_URL}/goals/updateSingle/` , {
        user_id: userid,
        goal_id: goalid,
        title: title,
        description: description,
        status:status,
        is_archived:isarchived,
        due_date: due_date,
        progress_bar:progress
      },
      {
          headers: {
              'Authorization': `${AUTH_TOKEN}`
          }
      }
  );
      return response.data;
    } catch (error) {
      console.error('Error updating goal:',error);
      throw error;
    }
    }
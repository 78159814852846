import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './SummaryTab.css';
import { fetchSummaries } from '../../services/summarizeService';

const SummaryTab = () => {
    const [summaries, setSummaries] = useState([]);
    const userId = localStorage.getItem('userId'); // Get user ID from localStorage
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchSummaries(userId);
                const sortedSummaries = response.progress_reports.sort(
                    (a, b) => new Date(b.latest_timestamp) - new Date(a.latest_timestamp)
                );
                setSummaries(sortedSummaries);
            } catch (error) {
                console.error('Error fetching summaries:', error);
            }
        };

        fetchData();
    }, [userId]);

    const handleExpandClick = (reportId) => {
        navigate(`/report/${reportId}`); // Navigate to the report page
    };

    return (
        <div className="summary-tab">
            <table className="summary-table">
                <thead>
                    <tr>
                        <th>REPORT TYPE</th>
                        <th>UPDATED</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {summaries.map((summary) => (
                        <tr key={summary.progress_report_id}>
                            <td className="summary-type">
                                {summary.report_type ? summary.report_type.replace('_', ' ') : 'N/A'}
                            </td>
                            <td className="summary-date">
                                {new Date(summary.latest_timestamp).toLocaleDateString()}
                            </td>
                            <td className="summary-arrow">
                                <span
                                    className="expand-arrow"
                                    onClick={() => handleExpandClick(summary.progress_report_id)} // Navigate on click
                                >
                                    &gt;
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SummaryTab;

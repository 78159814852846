import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchUserGoals } from '../../services/goalService';
import './Projects.css';
import NewProject from "./NewProject";

const ProjectsManager = () => {
    const userId = localStorage.getItem('userId');
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Fetch projects from API
    const fetchProjects = async () => {
        try {
            const projectsResponse = await fetchUserGoals(userId);
            setProjects(projectsResponse);
        } catch (error) {
            console.error('Error fetching projects:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, [userId]);

    const handleNewProjectSubmit = () => {
        // Refresh project list after submission
        fetchProjects();
    };

    const renderProjects = () => {
        return projects.map(project => (
            <div className="project-card" key={project.goal_id}>
                <Link to={`/project/${project.goal_id}`} className="project-link">
                    <h3>{project.title}</h3>
                    <p>{project.description}</p>
                </Link>
                <div className="stats">
                    <span className="stat-item" data-tooltip="Status">
                        <div className="status-badge">
                            {project.is_archived === 'False' ? 'Active' : 'Archived'}
                        </div>
                    </span>
                    <span className="stat-item" data-tooltip="Due Date">
                        <img src="icons/calendar.svg" alt="Due Date" className="icon" />
                        {new Date(project.due_date).toLocaleDateString()}
                    </span>
                    <span className="stat-item" data-tooltip="Updates">
                        <img src="icons/details.svg" alt="Updates" className="icon" />
                        {project.update_count}
                    </span>
                    <span className="stat-item" data-tooltip="Generated Documents">
                        <img src="icons/magic.svg" alt="Generated Docs" className="icon" />
                        {project.progress_report_count}
                    </span>
                </div>
            </div>
        ));
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="projects-manager">
            <div className="header">
                <h1>Your Projects</h1>
                <NewProject onSubmit={handleNewProjectSubmit} /> {/* Use self-contained modal */}
            </div>

            <div className="projects-container">
                {renderProjects()}
            </div>
        </div>
    );
};

export default ProjectsManager;

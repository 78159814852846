import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './UpdatesTab.css';

const UpdatesTab = ({ updates }) => {
    const navigate = useNavigate(); // Initialize navigate

    const handleUpdateClick = (updateId) => {
        navigate(`/update/${updateId}`); // Navigate to the single update page
    };

    // Sort updates by timestamp (most recent to least recent)
    const sortedUpdates = [...updates].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );

    return (
        <div className="updates-tab">
            <table className="updates-table">
                <thead>
                    <tr>
                        <th>TITLE</th>
                        <th>UPDATED</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedUpdates.map((update) => (
                        <tr key={update.update_id}>
                            <td className="update-title">
                                {update.message.length > 100 
                                    ? `${update.message.slice(0, 100)}...` 
                                    : update.message}
                            </td>
                            <td className="update-date">
                                {new Date(update.timestamp).toLocaleDateString()}
                            </td>
                            <td className="update-arrow">
                                <span
                                    className="expand-arrow"
                                    onClick={() => handleUpdateClick(update.update_id)} // Navigate on click
                                >
                                    &gt;
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UpdatesTab;

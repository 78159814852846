import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home3/Home3';
import Onboarding from './pages/Onboarding/Onboarding';
import LandingPage from './pages/LandingPage/LandingPage';
import ProgressSummary from './pages/ProgressSummary/ProgressSummary';
import Sidebar from './components/Sidebar/Sidebar';
import PrivacyPolicy from './components/Documents/PrivacyPolicy';
import TermsPage from './components/Documents/Terms';
import SingleProject from './pages/Project/SingleProject';
import SingleUpdate from './pages/Update/SingleUpdate';
import SingleReport from './pages/Report/SingleReport';

import './index.css'; // Assuming you have a CSS file for global styles

function App() {
  const isLoggedIn = !!localStorage.getItem('userId'); // Check if userId exists in localStorage

  return (
    <Router>
      <Routes>
        <Route path="/" element={isLoggedIn ? <Navigate to="/home" /> : <LandingPage />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/home" element={<Layout><Home /></Layout>} />
        <Route path="/summary/:goalId" element={<Layout><ProgressSummary /></Layout>} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsPage />} />
        <Route path="/project/:goalId" element={<SingleProject />} />
        <Route path="/update/:updateId" element={<SingleUpdate />} />
        <Route path="/report/:reportId" element={<SingleReport />} />

        <Route path="*" element={<Navigate to="/" />} /> {/* Redirect unknown routes */}
      </Routes>
    </Router>
  );
}

function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="app-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> {/* Pass state to Sidebar */}
      <div className={`content ${isSidebarOpen ? '' : 'collapsed'}`}>
        {children} {/* Render the page content */}
      </div>
    </div>
  );
}

export default App;

import React from "react";
import PropTypes from "prop-types";
import "./Modal.css";

const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  const handleOverlayClick = (e) => {
    // Close the modal if the click happens directly on the overlay
    if (e.target.className === "modal-overlay") {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <button
          className="modal-close-button"
          onClick={onClose}
          aria-label="Close Modal"
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;

import React, { useState, useEffect, useRef } from 'react';
import './GoalCard.css';
import { fetchSingleGoal, updateGoal, createNewGoal } from '../../services/goalService'; // Import the goal management functions
import { submitMessage, finalMessage } from '../../services/updatesService'; // Import the update functions
import dayjs from 'dayjs'
import { render } from '@testing-library/react';

let debounceTimeout; // Declare debounceTimeout globally to keep track

const GoalCard = ({
  initialGoal = {
    title: '',
    description: '',
    date: '',    goal_id: '',
    due_date: '',
    daysTillDue: 0,
    progress_bar: 0,
  },
  isNewGoal=false, // Default to false unless explicity set for new goals,
  onSave,
  onDelete,
  onCancel, // onCancel prop for new goals
  onExpand = () => {}// New prop to notify parent about expansion
}) => {
  const [goal, setGoal] = useState(initialGoal);
  const [expanded, setExpanded] = useState(false); // Expand/Collapse state
  const [updates, setUpdates] = useState([]); // State to store fetched updates
  const [error, setError] = useState(null);
  const [newUpdate, setNewUpdate] = useState(''); // State for the new update text
  const [completion, setCompletion] = useState(goal.progress_bar * 100); // Initialize slider with the progress_bar value
  const [currentPage, setCurrentPage] = useState(1); // Track current page for pagination
  const [updatesPerPage] = useState(5); // Number of updates per page
  const [isNextDisabled, setIsNextDisabled] = useState(false); // Disable the next button
  const [assistantMessage, setAssistantMessage] = useState(''); // State for assistant-generated message
  const [showAssistantMessage, setShowAssistantMessage] = useState(false); // To display the assistant message
  const [isEditingAssistantMessage, setIsEditingAssistantMessage] = useState(false); // Track editing state for the assistant message
  const [editedAssistantMessage, setEditedAssistantMessage] = useState(assistantMessage); // Temporary storage for the edited assistant message
  const [updateId, setUpdateId] = useState(null); // Track update ID for follow-up submissions
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Success message after final confirmation
  const [isModalVisible, setIsModalVisible] = useState(false); // Control modal visibility
  const [isEditingTitle, setIsEditingTitle] = useState(false); // Track if the goal title is being edited
  const [isTitleSaved, setIsTitleSaved] = useState(!isNewGoal); // Track if title is saved (true for existing goals)
  const [editedTitle, setEditedTitle] = useState(goal.title); // Track the new title
  const [description, setDescription] = useState(goal.description);
  const [dueDate, setDueDate] = useState(goal.due_date);
  const [isEditingDescription, setIsEditingDescription] = useState(false); // Track if the description is being edited
  const [isEditingDueDate, setIsEditingDueDate] = useState(false); // Track if the due date is being edited
  const descriptionRef = useRef(null); // Ref for the description input
  const titleRef = useRef(null);
  const [expandedUpdates, setExpandedUpdates] = useState({}); // State to track expanded updates
  const [isSubmitting, setIsSubmitting] = useState(false); // Add a new state for tracking submission
  const [editableUpdates, setEditableUpdates] = useState({}); // Track editable updates
  const [clarificationRequest, setClarificationRequest] = useState(''); // To store the clarification request
  const [updatedText, setUpdatedText] = useState({}); // Temporary storage for edited text
  const debounceTimeouts = useRef({}); // Track debounce timeouts for each update
  const [isTyping, setIsTyping] = useState(false); // Track typing animation
  const [showTextBox, setShowTextBox] = useState(false); // Show the text box after typing
  const typingTimeoutRef = useRef(null); // To clean up typing animation timeout
  const [typedText, setTypedText] = useState(''); // For typing effect



  const userId = localStorage.getItem('userId');
  
  // Auto-expand function that adjusts height based on scrollHeight
  const autoExpandTextarea = () => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = 'auto'; // Reset the height to auto to shrink if text is deleted
      descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`; // Adjust height based on content
    }
  };

  GoalCard.defaultProps = {
    onExpand: () => {}, // Default to a no-op function for onExpand
  };
  
  useEffect(() => {
    if (!isNewGoal && expanded) {
      // If the goal card is expanded, fetch the updates
      loadGoalUpdates();
    }
  }, [expanded, isNewGoal]);

   // Trigger the auto-expansion when editing starts
   useEffect(() => {
    if (isEditingDescription) {
      autoExpandTextarea(); // Expand when entering edit mode if there's already content
    }
  }, [isEditingDescription]);

  useEffect(() => {
    if (editedAssistantMessage !== assistantMessage && !isEditingAssistantMessage) {
      const timeout = setTimeout(() => {
        callFinalMessageService(updateId, editedAssistantMessage);
      }, 1000);
  
      return () => clearTimeout(timeout); // Cleanup on unmount or subsequent change
    }
  }, [editedAssistantMessage, isEditingAssistantMessage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isEditingTitle && titleRef.current && !titleRef.current.contains(event.target)) {
        handleSaveTitle(); // Save the title if clicking outside of the input
        setIsEditingTitle(false); // Disable editing mode
      }
    };
    // Attach the event listener when editing
    if (isEditingTitle) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up event listener on component unmount or when editing stops
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditingTitle]);

  const loadGoalUpdates = async () => {
    setError(null); // Reset error state
    try {
      const response = await fetchSingleGoal(goal.goal_id, userId);
      console.log(response)
      const sortedUpdates = (response.updates || []).sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp) // Sort by most recent
      );
      setUpdates(sortedUpdates); // Assume updates are in the response
    } catch (err) {
      setError('Error fetching goal updates');
    } finally {
    }
  };
  //  const handleInputResize = (e) => {
  //    e.target.style.height = 'auto'; // Reset the height to auto to shrink if text is deleted
  //    e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height based on the scroll height
  //  };
  

  const toggleExpand = () => {
    setExpanded(!expanded); // Toggle expand/collapse
    onExpand(!expanded);
  };

  // Handle the change in the title input
  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDueDateChange = (e) => {
    setDueDate(e.target.value);
  };
  
  const autoResize = (textarea) => {
    if (textarea) {
      console.log('Resizing textarea:', textarea); // Debugging output
      textarea.style.height = 'auto'; // Reset height to auto
      textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height to fit content
    } else {
      console.warn('Textarea element not found.'); // Warn if the element is missing
    }
  };

  // Handle text area changes with debounce
  const handleTextChange = (index, e) => {
    const { value } = e.target;
  
    // Update the state with the new value
    setUpdatedText((prev) => ({
      ...prev,
      [index]: value,
    }));
  
    // Clear any existing debounce for this index
    if (debounceTimeouts.current[index]) {
      clearTimeout(debounceTimeouts.current[index]);
    }
  
    // Set a new debounce timeout to save after a delay
    debounceTimeouts.current[index] = setTimeout(() => {
      callFinalMessageService(index, value); // Save the update
    }, 1000);
  };
  
  
  
  // Function to save the title before proceeding with creating or updating the goal
  const handleSaveTitle = async () => {
    if (isSubmitting || (isNewGoal && isTitleSaved)) {
      // Exit if submission is already in progress or title has been saved
      return;
    }
  
    setIsSubmitting(true); // Mark as submitting to prevent duplicate calls
  
    if (isNewGoal && !isTitleSaved) {
      try {
        const newGoal = await createNewGoal(userId, editedTitle, description, dueDate);
        setGoal(newGoal);
        setIsTitleSaved(true); // Mark the title as saved
        setIsEditingTitle(false); // Disable further editing of the title
        setExpanded(true);
        onSave(newGoal); // Notify parent about the new goal
      } catch (error) {
        console.error('Error creating new goal:', error);
      } finally {
        setIsSubmitting(false); // Reset submitting state
      }
    } else {
      try {
        await updateGoal(userId, goal.goal_id, editedTitle, description, dueDate);
        setGoal((prevGoal) => ({
          ...prevGoal,
          title: editedTitle,
          description,
          due_date: dueDate,
        }));
        setIsTitleSaved(true);
        setIsEditingTitle(false);
        onSave({ ...goal, title: editedTitle, description, due_date: dueDate });
      } catch (error) {
        console.error('Error updating goal title:', error);
      } finally {
        setIsSubmitting(false); // Reset submitting state
      }
    }
  };
  
  const handleTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior
      e.stopPropagation(); // Stop event propagation to prevent duplicates
      handleSaveTitle();
      setExpanded(true); // Expand the goal card
      setIsEditingDescription(true); // Enable description editing mode
      onExpand(true);
    }
  };
  

  // Double-click to enable editing the title for existing goals
  const handleTitleDoubleClick = () => {
    if (!isNewGoal) {
      setIsEditingTitle(true);
    }
  };


 // Handle Description Click
 const handleDescriptionClick = () => {
    setIsEditingDescription(true);
  };

  // Handle Due Date Double Click
  const handleDueDateClick = () => {
    setIsEditingDueDate(true);
   };

  // Save Description
  const handleSaveDescription = async () => {
    try {
      await updateGoal(userId, goal.goal_id, editedTitle, description, dueDate); // API call to save description
      
      // Update the local state to reflect the new description
      setGoal(prevGoal => ({
      ...prevGoal,
      description: description,
    }));

      setIsEditingDescription(false); // Stop editing
      onSave({ ...goal, description }); // Notify parent
    } catch (error) {
      console.error('Error updating goal description:', error);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent adding new lines
  
      // Get the edited value or fallback to the original message if no changes were made
      const value = updatedText[index] !== undefined ? updatedText[index] : updates[index].message;
  
      // Save the edited value using the backend API
      callFinalMessageService(index, value);
  
      // Update the specific object in the updates array
      setUpdates((prevUpdates) => {
        // Use a shallow copy of the array
        const updatedUpdates = [...prevUpdates];
  
        // Modify the existing object at the specified index
        if (updatedUpdates[index]) {
          updatedUpdates[index].message = value;
        }
  
        return updatedUpdates; // Return the modified array to update the state
      });
  
      // Exit edit mode for the update
      setEditableUpdates((prev) => ({ ...prev, [index]: false }));
    }
  };
  

  // Save Due Date
  const handleSaveDueDate = async () => {

    try {
      await updateGoal(userId, goal.goal_id, editedTitle, description, dueDate); // API call to save due date
      
      // Update the local state to reflect the new due date
      setGoal(prevGoal => ({
        ...prevGoal,
        due_date: dueDate,
      }));
      
      setIsEditingDueDate(false); // Stop editing
      onSave({ ...goal, due_date: dueDate }); // Notify parent
    } catch (error) {
      console.error('Error updating goal due date:', error);
    }
  };

  // Handle Keyboard event for Description
  const handleDescriptionKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSaveDescription();
    }
  };

  // Handle Keyboard event for Due Date
  const handleDueDateKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSaveDueDate();
    }
  };

  const handleArchiveGoal = async () => {
    try {
      // Assuming you have userId and goalId available
      const { goal_id } = goal;
  
      // Call the updateGoal API with isarchived set to true
      await updateGoal(userId, goal_id, goal.title, goal.description, goal.status, "True", goal.due_date, goal.progress_bar);
  
      // Call onDelete to remove the goal from the parent component's state
      onDelete(goal_id); // Pass goal_id to identify the goal to delete in parent component
    } catch (error) {
      console.error('Error archiving the goal:', error);
    }
    };
  

  // Handler to update the goal progress
  const handleSliderChange = async (e) => {
    const newCompletion = parseInt(e.target.value, 10);
    setCompletion(newCompletion);

    // Clear any previously set timeout
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout for debouncing the API call
    debounceTimeout = setTimeout(async () => {
      const progressDecimal = newCompletion / 100;
      try {
        // Call updateGoal API to save the new progress value after debounce
        await updateGoal(userId, goal.goal_id, null, null, null, null, null, progressDecimal);
        console.log(`Goal ${goal.goal_id} updated with ${progressDecimal * 100}% completion.`);
      } catch (error) {
        console.error('Error updating goal progress:', error);
      }
    }, 500); // Wait for 500ms (you can adjust this delay) after user stops moving the slider
  };

  // Pagination: Calculate the indices of the updates to show on the current page
  const indexOfLastUpdate = currentPage * updatesPerPage;
  const indexOfFirstUpdate = indexOfLastUpdate - updatesPerPage;
  const currentUpdates = updates.slice(indexOfFirstUpdate, indexOfLastUpdate); // Only show the updates for the current page

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(updates.length / updatesPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleAddUpdate = async () => {
    if (newUpdate.trim()) {
      setIsNextDisabled(true); // Disable the button during submission
      try {
        const response = await submitMessage(newUpdate, goal.goal_id);

        // Check if there's a clarification_request
        if (response.clarification_request) {
          setClarificationRequest('');
          setClarificationRequest(response.clarification_request);
          setIsTyping(true); // Start typing animation
        } else {
          // Proceed with normal assistant message handling
          setAssistantMessage(response.assistant_message);
          setEditedAssistantMessage(assistantMessage);
          setUpdateId(response.update_id);
          setShowAssistantMessage(true);
        }

        // Add the new update
        setUpdates([
          { timestamp: new Date(), message: newUpdate, updateId: response.update_id },
          ...updates,
        ]);
        setNewUpdate(''); // Clear input
      } catch (error) {
        console.error('Error submitting update:', error);
      } finally {
        setIsNextDisabled(false); // Re-enable the button
      }
    }
  };

  // Typing effect logic
  useEffect(() => {
    if (isTyping && clarificationRequest) {
      let index = 0;
      setTypedText('');
      const type = () => {
        if (index < clarificationRequest.length) {
            const nextChar = clarificationRequest[index]; // Get the current character
            setTypedText((prev) => prev + nextChar); // Append it to `typedText`
            console.log(`Appending character: ${nextChar}`); // Debug log
            index++; // Increment AFTER appending
            typingTimeoutRef.current = setTimeout(type, 50); // Schedule next character
        } else {
            setIsTyping(false); // Typing is complete
            setShowTextBox(true); // Show the clarification text box
        }
    };
      type();
    } else if (!clarificationRequest) {
      console.warn("clarificationRequest is empty or undefined.");
  }

  return () => clearTimeout(typingTimeoutRef.current); // Clean up timeout
  }, [isTyping, clarificationRequest]);

  // Handle blur event
  const handleBlur = (index) => {
    // Get the edited value or fallback to the original message if no changes were made
    const value = updatedText[index] !== undefined ? updatedText[index] : updates[index].message;
  
    // Save the edited value using the backend API
    callFinalMessageService(index, value);
  
    // Update the specific object in the updates array
    setUpdates((prevUpdates) => {
      // Use a shallow copy of the array
      const updatedUpdates = [...prevUpdates];
  
      // Modify the existing object at the specified index
      if (updatedUpdates[index]) {
        updatedUpdates[index].message = value;
      }
  
      return updatedUpdates; // Return the modified array to update the state
    });
  
    // Exit edit mode for the update
    setEditableUpdates((prev) => ({ ...prev, [index]: false }));
  };
  


  // Call the finalMessage service
  const callFinalMessageService = async (index, value) => {
    const update = updates[index];
  
    // Check for valid update and update_id
    if (!update || !update.update_id) {
      console.error(`Missing or invalid update for index ${index}.`);
      return;
    }
  
    try {
      await finalMessage(value, update.update_id); // Pass blank userId if undefined
      console.log(`Update ${update.update_id} successfully saved.`);
  
      // Update the updates array with the new message
      const updatedUpdates = updates.map((update) =>
        update.updateId === updateId ? { ...update, message: value } : update
      );
      setUpdates(updatedUpdates);
    } catch (error) {
      console.error(`Error saving update ${update.update_id}:`, error);
    }
  };

  // Function to confirm the final message
  const handleConfirm = async () => {
    try {
      await finalMessage(editedAssistantMessage, updateId); // Save the edited message
      setShowSuccessMessage(true);
  
      // Update the assistant message in updates
      const updatedUpdates = updates.map((update) =>
        update.updateId === updateId ? { ...update, message: editedAssistantMessage } : update
      );
      setUpdates(updatedUpdates);
  
      // Clear editing state
      setIsEditingAssistantMessage(false);
      setEditedAssistantMessage('');
      setAssistantMessage('');
      setShowAssistantMessage(false);
      setIsNextDisabled(false);
      setIsModalVisible(false);
  
      // Show success message for 3 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    } catch (error) {
      console.error('Error confirming message:', error);
    }
  };
  
  const handleEditAssistantMessage = () => {
    setIsEditingAssistantMessage(true);
    setEditedAssistantMessage(assistantMessage); // Pre-fill with the current message
  };
  
  const handleSaveAssistantMessage = () => {
    setIsEditingAssistantMessage(false);
    setAssistantMessage(editedAssistantMessage); // Save the edited message
  };
  
  
  const renderAssistantMessage = () => {
    if (isEditingAssistantMessage) {
      return (
        <textarea
          className="edit-update-textarea"
          value={editedAssistantMessage}
          onChange={(e) => {
            setEditedAssistantMessage(e.target.value); // Update state on change
            autoResize(e.target); // Dynamically resize the textarea
          }} // Update state on change
          onBlur={handleSaveAssistantMessage} // Save on blur
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSaveAssistantMessage();
            }
          }}
          autoFocus
          ref={(textarea) => {
            if (textarea) autoResize(textarea); // Resize immediately on render
          }}
        />
      );
    }
  
    return (
      <p
        className="goal-update-message"
        onClick={handleEditAssistantMessage} // Enable edit mode on click
      >
        {assistantMessage || 'Click to edit the suggested revision...'}
      </p>
    );
  };
  

  const renderUpdateContent = (update, index) => {
    const isEditable = editableUpdates[index];
    const currentText = updatedText[index] ?? update.message;
  
    if (isEditable) {
      return (
        <textarea
          className={`edit-update-textarea`}
          value={currentText}
          onChange={(e) => {
            handleTextChange(index, e); // Update the text
            autoResize(e.target); // Dynamically resize the textarea
          }}
          onBlur={() => handleBlur(index)} // Trigger save on blur
          onKeyDown={(e) => handleKeyDown(index, e)} // Save on Enter key
          autoFocus
          ref={(textarea) => {
            if (textarea) autoResize(textarea); // Resize immediately on becoming editable
          }}
        />
      );
    }
  
    return (
      <p
        className="goal-update-message"
        onClick={() => setEditableUpdates((prev) => ({ ...prev, [index]: true }))} // Enable edit mode
      >
        {update.message}
      </p>
    );
  };
  

  return (
    <div className="goal-card-container">
       <div className="goal-card-header" onClick={toggleExpand}>
        {isNewGoal || isEditingTitle ? (
          <input
            ref={titleRef} // Attach the ref to the title input  
            type="text"
            value={editedTitle}
            onChange={handleTitleChange}
            onBlur={handleSaveTitle} // Save on blur
            onKeyDown={handleTitleKeyDown}
            autoFocus
            className="goal-title-input"
            placeholder="Enter a goal title"
          />
        ) : (
          <h3 className="goal-title-header" onDoubleClick={handleTitleDoubleClick}>
            {goal.title || 'Goal title'}
          </h3>
        )}
        {!isNewGoal && <button className="expand-button">{expanded ? '-' : '+'}</button>}
      </div>

      {expanded && (
        <div className="goal-card">
          <div className="goal-card-details">
            {/* Description Field */}
            <div className={`goal-description ${isEditingDescription ? 'goal-description-editing' : ''}`}>
              <img
                src="/icons/details.svg"
                alt="Card Icon"
                className="card-icon"
                onClick={handleDescriptionClick}
              />
              {isEditingDescription ? (
                <textarea
                  ref={descriptionRef}
                  value={description}
                  onChange={handleDescriptionChange}
                  onBlur={handleSaveDescription}
                  onKeyDown={handleDescriptionKeyDown}
                  autoFocus
                  className="goal-description-input expanding"
                  rows={2} // Start with a single line
                />
              ) : (
                <span onClick={handleDescriptionClick}>
                  {goal.description || 'Goal description'}
                </span>
              )}
            </div>

            <div className="goal-due-date">
            <img src="/icons/flag.svg" alt="Card Icon" className="card-icon" />
            {isEditingDueDate ? (
                <input
                  type="date"
                  value={dueDate}
                  onChange={handleDueDateChange}
                  onBlur={handleSaveDueDate}
                  onKeyDown={handleDueDateKeyDown}
                  autoFocus
                  className="goal-due-date-input"
                />
              ) : (
                <span onClick={handleDueDateClick}>
                  {goal.due_date ? dayjs(goal.due_date).format('MMMM D, YYYY') : 'No due date'}
                </span>
              )}
            </div>
          </div>

          {/* Display error message */}
          {error && <div className="error-message">{error}</div>}

          {/* Progress Slider */}
          <div className="progress-slider-section shared-width">
            <label className="completion-label">{completion}% complete</label>
            <input
              type="range"
              className="progress-slider"
              min="0"
              max="100"
              value={completion}
              onChange={handleSliderChange} // Use the debounced handler for slider change
              style={{
                '--completion': completion, // Use the completion value for dynamic background
              }}
            />
          </div>

          {/* Input section with arrow icon */}
          {!clarificationRequest && !showAssistantMessage && (
            <div className="update-input-section shared-width">
              <textarea
                className="update-textarea-expandable"
                placeholder={updates.length === 0 ? "Add your first update..." : "Add an update..."}
                value={newUpdate}
                onChange={(e) => setNewUpdate(e.target.value)}
                onInput={(e) => {
                  e.target.style.height = "auto" // Reset height to auto to calculcate scroll height
                  e.target.style.height = `${Math.min(e.target.scrollHeight, 6 * 24)}px`;
                }
              }
                rows={1} // Start with a single line
                disabled={isNextDisabled}
              />
              <button
                className="submit-update-button"
                onClick={handleAddUpdate}
                disabled={isNextDisabled}
              >
                <img src="/icons/send.svg" alt="Card Icon" className="card-icon" />
              </button>
            </div> 
          )}
          
          {clarificationRequest && (
        <div className="clarification-section">
          <p className="clarification-text">{typedText || clarificationRequest}</p>
          {showTextBox && (
            <textarea
              className="clarification-textbox"
              placeholder="Provide your clarification here..."
              value={newUpdate}
              onChange={(e) => setNewUpdate(e.target.value)}
              rows={2}
            />
          )}
        </div>
      )}        
          
          {/* AI-based suggested revision */}
          {showAssistantMessage && (
            <div className="ai-suggestion-box">
              <div className="ai-suggestion-header">
                <i className="ai-icon">🤖</i> Suggested Revision
              </div>
              <div className="ai-suggestion-body">
                <p>{renderAssistantMessage()}</p>
                </div>
                <div className="ai-suggestion-button-container">
                <button className="confirm-button" onClick={handleConfirm}>
                  Confirm
                </button>
                </div>
            </div>
          )}
          
          {/* Add a separator between progress slider and previous updates */}
          {updates.length > 0 && (
          <div className="section-separator">
          {/* Conditionally display "Previous Updates" header and Summarize Progress button */}
            <div className="updates-header-container">
              <h4 className="previous-update-header">Previous Updates</h4>
              {/* <button className="summarize-progress-button">Summarize</button> */}
            </div>
            </div>
          )}

          {/* Display existing updates with pagination */}
          <div className="goal-updates">
            <ul>
              {currentUpdates.map((update, index) => (
                <div className="goal-update" key={index}>
                  <li>
                    <span className="goal-update-date">
                      {new Date(update.timestamp).toLocaleString()}
                    </span>
                    {renderUpdateContent(update, index)}
                  </li>
                </div>
              ))}
            </ul>
          </div>
          {/* Pagination controls */}
          {updates.length > updatesPerPage && (
            <div className="pagination">
              {pageNumbers.map((number) => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  className={`page-number-button ${currentPage === number ? 'active' : ''}`}
                >
                  {number}
                </button>
              ))}
            </div>
          )}
          {/* Section Separator */}
          <div className="section-separator"></div>

          {/* Archive button section */}
          <div className="archive-section">
          <button className="archive-button" onClick={handleArchiveGoal}>
            Archive
          </button>
        </div>
        </div>
      )}
    </div>
    
  );
};

export default GoalCard;
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { fetchReport } from '../../services/summarizeService'; // Adjust path as needed
import './SingleReport.css';

const SingleReport = () => {
    const { reportId } = useParams(); // Get the report ID from the URL
    const [report, setReport] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [copySuccess, setCopySuccess] = useState("");
    const contentRef = useRef(null); // Reference to the content box for copying

    useEffect(() => {
        const fetchData = async () => {
            try {
                const reportData = await fetchReport(reportId); // Fetch report data
                setReport(reportData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching report:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [reportId]);

    const handleCopyToClipboard = async () => {
        if (contentRef.current) {
            try {
                const htmlContent = contentRef.current.innerHTML; // Extract HTML content
                const clipboardData = new ClipboardItem({
                    'text/html': new Blob([htmlContent], { type: 'text/html' }),
                    'text/plain': new Blob([contentRef.current.innerText], { type: 'text/plain' }),
                });
                await navigator.clipboard.write([clipboardData]); // Write to clipboard

                setCopySuccess("Copied!");
                setTimeout(() => setCopySuccess(""), 2000); // Reset success message after 2 seconds
            } catch (err) {
                console.error("Failed to copy text: ", err);
            }
        }
    };

    if (isLoading) return <div>Loading...</div>;

    if (!report) return <div>Error loading report data.</div>;

    return (
        <div className="page">
            <div className="page-content">
                <div className="breadcrumb">
                    <a href="/home">Home</a> / Report Details
                </div>
                <div className="report-header">
                    <h1 className="title">{report.report_type}</h1>
                    <button className="copy-button" onClick={handleCopyToClipboard}>
                        Copy to Clipboard
                    </button>
                    {copySuccess && <span className="copy-success">{copySuccess}</span>}
                    <div className="metadata">
                        <p>Last Updated: {new Date(report.latest_timestamp).toLocaleString()}</p>
                    </div>
                </div>
                <div className="content-box" ref={contentRef}>
                    <ReactMarkdown>{report.latest_message}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
};

export default SingleReport;

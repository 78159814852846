import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PREFIX;
const AUTH_TOKEN = process.env.REACT_APP_ENTROV_TOKEN;

export const getUnassigned = async (userId) => {
  try {
    const response = await axios.post(`${API_URL}/unassignedUpdates/`, {
      user_id: userId, // Send the user_id in the JSON payload
    }, {
      headers: {
        Authorization: `${AUTH_TOKEN}`,
        'Content-Type': 'application/json', // Ensure content type is JSON
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching unassigned updates:', error);
    throw error;
  }
};

export const getAssigned = async (userId) => {
  try {
    const response = await axios.post(`${API_URL}/assignedUpdates/`, {
      user_id: userId, // Send the user_id in the JSON payload
    }, {
      headers: {
        Authorization: `${AUTH_TOKEN}`,
        'Content-Type': 'application/json', // Ensure content type is JSON
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching unassigned updates:', error);
    throw error;
  }
};

export const updateUpdate = async (updateId, userId) => {
  try {
    const response = await axios.post(`${API_URL}/updateUpdate/`, {
      update_id:updateId,
      user_id: userId,
    },
    {
      headers: {
          'Authorization': `${AUTH_TOKEN}`
      }
  });
    return response.data;
  } catch (error) {
    console.error('Error submitting message:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const submitMessage = async (message, goalId, userId = null, updateType = "general") => {
  try {
    const response = await axios.post(
      `${API_URL}/submitMessage/`,
      {
        message,
        goal_id: goalId,
        user_id: userId,
        update_type: updateType, // Include update_type in the payload
      },
      {
        headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`, // Use Bearer token for authorization
          "Content-Type": "application/json", // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting message:", error.response?.data || error.message);
    throw new Error(
      error.response?.data?.message || "An error occurred while submitting the message."
    );
  }
};

export const finalMessage = async (action, message, updateId) => {
    try {
      const response = await axios.post(`${API_URL}/finalMessage/`, {
        action: action,
        message:message,
        update_id: updateId,
      },
      {
        headers: {
            'Authorization': `${AUTH_TOKEN}`
        }
    });
      return response.data;
    } catch (error) {
      console.error('Error sending final message:', error);
      throw error;
    }
  };

  export const fetchUpdate = async (updateId) => {
    try {
      const response = await axios.post(`${API_URL}/fetchUpdate/`, {
        update_id: updateId,
      },
      {
        headers: {
            'Authorization': `${AUTH_TOKEN}`
        }
    });
      return response.data;
    } catch (error) {
      console.error('Error sending final message:', error);
      throw error;
    }
  };
// Home.js
import React from 'react';
import UpdatesManager from '../../components/Updates/UnassignedUpdates';
import ProjectsManager from '../../components/Projects/Projects';
import './Home3.css';  



function Home() {
  const userName = localStorage.getItem('userName');
  return (
    <div className="home-page">
          <div className="page-content"> 
            <div className="greeting-section">
            <h2>
              <span className="greeting-text">Hello, </span><br />
              <span className="user-name">{userName} 👋</span>
            </h2>
          </div>
      <div className="updates-manager-container">
        <UpdatesManager />
      </div>
      <div className="projects-manager-container">
        <ProjectsManager />
      </div>
    </div>
    </div>

  );
}

export default Home;

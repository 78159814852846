import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { getUnassigned, getAssigned } from "../../services/updatesService";
import { fetchUserGoals } from "../../services/goalService";
import { linkGoals } from "../../services/linkService";
import NewUpdate from "./NewUpdate";
import "./UnassignedUpdates.css";

const UpdatesManager = () => {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate(); // Initialize navigate
  const [updates, setUpdates] = useState([]);
  const [classifiedUpdates, setClassifiedUpdates] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUpdates, setSelectedUpdates] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [activeTab, setActiveTab] = useState("unclassified");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const updatesResponse = await getUnassigned(userId);
        const projectsResponse = await fetchUserGoals(userId);
        setUpdates(updatesResponse.updates);
        setProjects(projectsResponse);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [userId]);

  const fetchClassifiedUpdates = async () => {
    try {
      setIsLoading(true);
      const response = await getAssigned(userId);
      setClassifiedUpdates(response.updates);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching classified updates:", error);
      setIsLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
    if (tab === "classified") {
      fetchClassifiedUpdates();
    }
  };

  const handleCheckboxChange = (updateId) => {
    setSelectedUpdates((prevSelected) =>
      prevSelected.includes(updateId)
        ? prevSelected.filter((id) => id !== updateId)
        : [...prevSelected, updateId]
    );
  };

  const handleSelectAll = (e, updatesList) => {
    if (e.target.checked) {
      setSelectedUpdates(updatesList.map((update) => update.update_id));
    } else {
      setSelectedUpdates([]);
    }
  };

  const handlePaginationChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleProjectSelect = (e) => {
    setSelectedProject(e.target.value);
  };

  const handleLinkClick = async () => {
    if (!selectedProject) {
      alert("Please select a project to link updates.");
      return;
    }

    try {
      await Promise.all(
        selectedUpdates.map((updateId) => linkGoals(updateId, [selectedProject]))
      );
      setSelectedUpdates([]); // Clear selections
      setSelectedProject(null); // Reset project selection
      window.location.reload(); // Trigger page reload
    } catch (error) {
      console.error("Error linking updates:", error);
      alert("Failed to link updates. Please try again.");
    }
  };

  const handleUpdateClick = (updateId) => {
    navigate(`/update/${updateId}`); // Navigate to SingleUpdate page with updateId
  };

  const renderUpdatesTable = (updatesList) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = updatesList.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <table className="updates-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) => handleSelectAll(e, updatesList)}
                checked={
                  updatesList.length > 0 &&
                  selectedUpdates.length === updatesList.length
                }
              />
            </th>
            <th>TITLE</th>
            <th>TYPE</th>
            <th>CREATED</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((update) => (
            <tr key={update.update_id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedUpdates.includes(update.update_id)}
                  onChange={() => handleCheckboxChange(update.update_id)}
                />
              </td>
              <td
                className="clickable-title"
                onClick={() => handleUpdateClick(update.update_id)} // Add onClick handler
              >
                {update.title}
              </td>
              <td>{update.type || "N/A"}</td>
              <td>{new Date(update.created_date).toLocaleDateString()}</td>
              <td>
                <span
                  className="expand-arrow"
                  onClick={() => handleUpdateClick(update.update_id)} // Navigate when clicked
                >
                  &gt;
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderPagination = (updatesList) => {
    const totalPages = Math.ceil(updatesList.length / itemsPerPage);
    if (totalPages <= 1) return null; // Only render pagination if there are multiple pages

    return (
        <div className="pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                    key={page}
                    className={`pagination-button ${
                        currentPage === page ? "active" : ""
                    }`}
                    onClick={() => handlePaginationChange(page)}
                >
                    {page}
                </button>
            ))}
        </div>
    );
};

  if (isLoading) return <div>Loading...</div>;

  const activeUpdates =
    activeTab === "unclassified" ? updates : classifiedUpdates;

  return (
    <div className="updates-manager">
      <div className="header">
        <h1>Your Updates</h1>
        <NewUpdate goals={projects} onSubmit={() => console.log("Update submitted")} />
      </div>

      <div className="tabs">
        <button
          className={`tab ${activeTab === "unclassified" ? "active" : ""}`}
          onClick={() => handleTabChange("unclassified")}
        >
          Unclassified
        </button>
        <button
          className={`tab ${activeTab === "classified" ? "active" : ""}`}
          onClick={() => handleTabChange("classified")}
        >
          Classified
        </button>
      </div>

      {renderUpdatesTable(activeUpdates)}
      {renderPagination(activeUpdates)}

      {selectedUpdates.length > 0 && (
        <div className="action-bar">
          <select
            className="project-dropdown"
            value={selectedProject || ""}
            onChange={handleProjectSelect}
          >
            <option value="" disabled>
              Select a project
            </option>
            {projects.map((project) => (
              <option key={project.goal_id} value={project.goal_id}>
                {project.title}
              </option>
            ))}
          </select>
          <button className="link-button" onClick={handleLinkClick}>
            Link
          </button>
        </div>
      )}
    </div>
  );
};

export default UpdatesManager;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { fetchUpdate } from '../../services/updatesService'; // Adjust path as needed
import './SingleUpdate.css';

const SingleUpdate = () => {
    const { updateId } = useParams();
    const navigate = useNavigate();
    const [update, setUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeAction, setActiveAction] = useState(""); // Tracks the current action
    const [newContext, setNewContext] = useState(""); // Context for the "Add more context" action
    const [assistantMessage, setAssistantMessage] = useState(""); // AI response
    const [isEditing, setIsEditing] = useState(false); // Edit mode control
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    useEffect(() => {
        const fetchData = async () => {
            try {
                const updateData = await fetchUpdate(updateId);
                setUpdate(updateData);
                setAssistantMessage(updateData.message); // Initialize assistant message
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching update:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [updateId]);

    const handleActionClick = (action, message) => {
        console.log(`Placeholder action: ${action}, with message: ${message}`);
    };

    const handleDelete = () => {
        console.log(`Deleting update with ID: ${updateId}`);
        navigate('/home');
    };

    const handleArchive = () => setIsModalOpen(!isModalOpen);

    if (isLoading) return <div>Loading...</div>;

    if (!update) return <div>Error loading update data.</div>;

    return (
        <div className="page">
            <div className="page-content">
                <div className="breadcrumb">
                    <a href="/home">Home</a> / Update Details
                </div>
                <div className="project-header">
                    <div className="header-row">
                        <h1>{update.title || 'Untitled Update'}</h1>
                    </div>
                    <div className="metadata">
                        <p>Created: {new Date(update.created_date).toLocaleString()}</p>
                        <p>Last Updated: {new Date(update.timestamp).toLocaleString()}</p>
                        <p>Type: {update.type || 'N/A'}</p>
                    </div>
                </div>
                <div className="content-box">
                    {!isEditing ? (
                        <ReactMarkdown>{assistantMessage}</ReactMarkdown>
                    ) : (
                        <textarea
                            value={assistantMessage}
                            onChange={(e) => setAssistantMessage(e.target.value)}
                            className="new-update-textarea editable-textarea-large"
                            placeholder="Edit the message..."
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SingleUpdate;

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PREFIX;
const AUTH_TOKEN = process.env.REACT_APP_ENTROV_TOKEN;

// Function to log in or create a user
export const fetchProgressReport = async (goalId, forceUpdate = "true", start = null,end = null) => {
  try {
    const response = await axios.post(`${API_URL}/goals/progress_report/`, {
      goal_id: goalId,  // GoalID from passed variable
      force_update: forceUpdate, // True or false, true returns new version. False returns most recent version
      start_date: start, // start date to pull updates from
      end_date: end // end date of pulling updates from
    },
    {
      headers: {
        'Authorization': `${AUTH_TOKEN}`,  // Example of adding an Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};


export const fetchReport = async (reportId) => {
  try {
    const response = await axios.post(`${API_URL}/report/fetch/`, {
      report_id: reportId,  // ReportId
    },
    {
      headers: {
        'Authorization': `${AUTH_TOKEN}`,  // Example of adding an Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

// Function to log in or create a user
export const newSummary = async (userId, goalId, forceUpdate = "true", reportType, start = null,end = null) => {
  try {
    const response = await axios.post(`${API_URL}/report/goal_report/`, {
      user_id: userId,
      goal_id: goalId,  // GoalID from passed variable
      force_update: forceUpdate, // True or false, true returns new version. False returns most recent version
      start_date: start, // start date to pull updates from
      end_date: end, // end date of pulling updates from
      report_type: reportType
    },
    {
      headers: {
        'Authorization': `${AUTH_TOKEN}`,  // Example of adding an Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const fetchSummaries = async (userId) => {
  try {
    const response = await axios.post(`${API_URL}/report/fetch/`, {
      user_id: userId,
    },
    {
      headers: {
        'Authorization': `${AUTH_TOKEN}`,  // Example of adding an Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

// Function to log in or create a user
export const saveProgressReport = async (reportId, message) => {
    try {
      const response = await axios.post(`${API_URL}/goals/progress_report/`, {
        progress_report_id: reportId,  // reportId from passed variable
        message:message
      },
      {
        headers: {
          'Authorization': `${AUTH_TOKEN}`,  // Example of adding an Authorization header
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  };